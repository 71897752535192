import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';
import axios from 'axios'; // Import axios

const { Option } = Select;
const token = localStorage.getItem('userToken');

const IDActivation = () => {
  const [formData, setFormData] = useState({
    userId: '',
    name: '',
    fundWallet: '',
    package: '',
    investmentAmount: '',
  });
  const [teamData, setTeamData] = useState([]); 
  const [packages, setPackages] = useState([]); 
  const [isSubmitting, setIsSubmitting] = useState(false); // To disable button

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/transfer-history',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
        setTeamData(response.data.transferHistory); 
      } catch (error) {
        console.error('Error fetching mining data:', error);
        message.error('Error fetching mining data');
      }
    };

    fetchTeamData();
  }, [token]);

  useEffect(() => {
    const fetchMiningPackages = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/get-mining-package',
          headers: { 
            'Authorization': `Bearer ${token}`,
          }
        };
        
        const response = await axios.request(config);
        setPackages(response.data || []); 
      } catch (error) {
        console.error('Error fetching mining packages:', error);
        message.error('Failed to fetch mining packages');
      }
    };
    
    const fetchUserDetails = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/user',
          headers: { 
            'Authorization': `Bearer ${token}`,
          }
        };
        
        const response = await axios.request(config);
        const userDetails = response.data.data;

        setFormData({
          userId: userDetails.userName || '',
          name: userDetails.fullName || '',
          fundWallet: userDetails.incomeWallet || '0',
          package: '',
          investmentAmount: '',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
        message.error('Failed to fetch user details');
      }
    };

    fetchMiningPackages();
    fetchUserDetails();
  }, []);

  const handleUpdateClick = async () => {
    try {
      if (!formData.investmentAmount) {
        message.error('Please fill out all required fields');
        return;
      }
  
      setIsSubmitting(true); // Disable button after clicking
  
      const amount = parseFloat(formData.investmentAmount); // Ensure it's a number
      if (isNaN(amount)) {
        message.error('Invalid investment amount');
        setIsSubmitting(false);
        return;
      }
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.vertexglobal.io/auth/v1/transfer-fund',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        data: {
          amount: amount, // Pass the parsed number value
        },
      };
  
      const response = await axios.request(config);
      console.log('Response:', response.data);
  
      message.success('Mining amount added successfully!');
  
      // Refresh the page or fetch data again
      window.location.reload(); // Refresh the page
  
    } catch (error) {
      console.error('Error during mining activation:', error);
      message.error('Failed to add mining amount');
    } finally {
      setIsSubmitting(false); // Re-enable button after process
    }
  };
  
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      package: value,
    });
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Transfer Funds</h1>
        <div className="form-comp full">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Full Name"
              name="userId"
              value={formData.userId}
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="floatingInput">User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="floatingInput">Name *</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Fund Wallet"
              name="fundWallet"
              value={formData.fundWallet}
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="floatingInput">Income Wallet</label>
          </div>
  
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Investment Amount"
              name="investmentAmount"
              value={formData.investmentAmount}
              onChange={handleInputChange}
            />
            <label htmlFor="floatingInput">Amount *</label>
          </div>
          <button
            id="primary-btn"
            onClick={handleUpdateClick}
            disabled={isSubmitting} // Disable button if submitting
          >
            {isSubmitting ? 'Processing...' : 'Save'}
          </button>
        </div>
      </div>
      <DataTable heading="Fund Transfer History" data={teamData || []} />
    </main>
  );
};

export default IDActivation;
