import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';

const MyProfile = () => {
  const [profile, setProfile] = useState({
    fullName: '',
    mobile: '',
    email: '',
    country: '',
    referralCode: '',
    sponsorName: '',
  });

 const token = localStorage.getItem("userToken");
  useEffect(() => {
    const fetchUserData = async () => {
      try {

       
        const response = await axios.get('https://api.vertexglobal.io/auth/v1/user', {
          headers: { 
            'Authorization':`Bearer ${token}`
          }
        });
        const data = response.data.data;
        setProfile({
          fullName: data.fullName,
          mobile: data.phone,
          email: data.email,
          country: data.country,
          referralCode: data.referralCode,
          sponsorName: data.sponsorName,
        });
      } catch (error) {
        message.error('Failed to fetch profile data');
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateClick = async () => {
    try {
     
      const data = JSON.stringify({
        fullName: profile.fullName,
        referralCode: profile.referralCode,
        sponsorName: profile.sponsorName,
        country: profile.country,
        email: profile.email,
        phone: profile.mobile,
        idActivationStatus: false, 
        termsCondition: true,
      });

      const response = await axios.patch('https://api.vertexglobal.io/auth/v1/user', data, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      message.success('Profile updated successfully!');
    } catch (error) {
      console.log(error);
      message.error('Failed to update profile');
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">My Profile</h1>
        <div className="form-comp">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputFullName"
              placeholder="Full Name"
              value={profile.fullName}
              onChange={(e) => setProfile({ ...profile, fullName: e.target.value })}
            />
            <label htmlFor="floatingInputFullName">Full Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInputMobile"
              placeholder="Mobile"
              value={profile.mobile}
              onChange={(e) => setProfile({ ...profile, mobile: e.target.value })}
            />
            <label htmlFor="floatingInputMobile">Mobile</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInputEmail"
              placeholder="Email Address"
              value={profile.email}
              disabled
              onChange={(e) => setProfile({ ...profile, email: e.target.value })}
            />
            <label htmlFor="floatingInputEmail">Email Address</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputCountry"
              placeholder="Country"
              value={profile.country}
              onChange={(e) => setProfile({ ...profile, country: e.target.value })}
            />
            <label htmlFor="floatingInputCountry">Country</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputreferralCode"
              placeholder="Sponsor ID"
              value={profile.referralCode}
              onChange={(e) => setProfile({ ...profile, referralCode: e.target.value })}
            />
            <label htmlFor="floatingInputreferralCode">Sponsor ID</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputSponsorName"
              placeholder="Sponsor Name"
              value={profile.sponsorName}
              onChange={(e) => setProfile({ ...profile, sponsorName: e.target.value })}
            />
            <label htmlFor="floatingInputSponsorName">Sponsor Name</label>
          </div>
          <button id="primary-btn" onClick={handleUpdateClick}>Update</button>
        </div>
      </div>
    </main>
  );
};

export default MyProfile;
