import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from '../../../components/Table/Table';

const RewardIncome = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMiningData();
  }, []);

  const fetchMiningData = async () => {
    try {
      setLoading(true); 
      const response = await axios.get('https://api.vertexglobal.io/auth/v1/direct-referral', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}` 
        }
      });

      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching mining data:', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <DataTable heading="Direct Referral" data={data } loading={loading} />
    </>
  );
};

export default RewardIncome;
