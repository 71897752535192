import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [activationMenuOpen, setActivationMenuOpen] = useState(false);
  const [isFundManagerOpen, setIsFundManagerOpen] = useState(false);
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [isCoinOpen, setIsCoinOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('userToken'); 
    window.location.href = '/';
  };
  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      window.location.href = '/';
    }
  }, []);

  const isActive = (path) => (location.pathname === path ? "active-nav" : "");

  useEffect(() => {
    if (location.pathname.includes("/profile")) {
      setProfileMenuOpen(true);
    } else if (location.pathname.includes("/activation")) {
      setActivationMenuOpen(true);
    } else if (location.pathname.startsWith("/fund-manager")) {
      setIsFundManagerOpen(true);
    } else if (location.pathname.startsWith("/team-member")) {
      setIsTeamOpen(true);
    } else if (location.pathname.startsWith("/coin")) {
      setIsCoinOpen(true);
    } else if (location.pathname.startsWith("/report")) {
      setIsReportOpen(true);
    } else if (location.pathname.startsWith("/wallet")) {
      setIsWalletOpen(!isWalletOpen);
    }
  }, [location.pathname]);


  const handleResize = () => {
    if (window.innerWidth > 768) {
      // For desktop, keep sidebar open
      setIsOpen(true);
    } else {
      // For mobile, keep sidebar closed
      setIsOpen(false);
    }
  };

  useEffect(() => {
   
    handleResize();

   
    window.addEventListener("resize", handleResize);

    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>

      <aside
        className={`sidebar sidebar-default navs-rounded ${
          isOpen ? "active" : ""
        }`}
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <a
            href="/dashboard"
            className="navbar-brand dis-none align-items-center"
          >
               
        
     
            <img src={Logo} className="img-fluid" alt="vertex" />
          
          </a>
        </div>
        <div className="sidebar-body p-0 data-scrollbar">
          <div className="navbar-collapse pe-3" id="sidebar">
            <ul className="navbar-nav iq-main-menu">
              <li className={`nav-item ${isActive("/dashboard")}`}>
                <a className="nav-link" href="/dashboard">
                  <i className="icon">
                    <svg
                      width="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Dashboard</span>
                </a>
              </li>
              {/* Profile Menu */}
              <li className={`nav-item ${profileMenuOpen ? "active" : ""}`}>
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#sidebar-profile"
                  role="button"
                  aria-expanded={profileMenuOpen}
                  aria-controls="sidebar-profile"
                  onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Profile</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${
                    profileMenuOpen ? "show" : ""
                  }`}
                  id="sidebar-profile"
                  data-bs-parent="#sidebar"
                >
                  <li className={`nav-item ${isActive("/profile/my-profile")}`}>
                    <a className="nav-link" href="/profile/my-profile">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">My Profile</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/profile/change-password"
                    )}`}
                  >
                    <a className="nav-link" href="/profile/change-password">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">Change Password</span>
                    </a>
                  </li>
      
                </ul>
              </li>
              {/* Activation Manager */}
              <li className={`nav-item ${activationMenuOpen ? "active" : ""}`}>
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#sidebar-activation"
                  role="button"
                  aria-expanded={activationMenuOpen}
                  aria-controls="sidebar-activation"
                  onClick={() => setActivationMenuOpen(!activationMenuOpen)}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M16.3345 1.99976H7.66549C4.27649 1.99976 2.00049 4.37776 2.00049 7.91676V16.0838C2.00049 19.6218 4.27649 21.9998 7.66549 21.9998H16.3335C19.7235 21.9998 21.9995 19.6218 21.9995 16.0838V7.91676C21.9995 4.37776 19.7235 1.99976 16.3345 1.99976Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M12.0005 15.2497C11.7245 15.2497 11.4485 15.1467 11.2435 14.9417L9.00049 12.6977C8.60949 12.3077 8.60949 11.6757 9.00049 11.2847C9.39149 10.8937 10.0235 10.8937 10.4145 11.2847L12.0005 12.8707L13.5865 11.2847C13.9775 10.8937 14.6095 10.8937 15.0005 11.2847C15.3915 11.6757 15.3915 12.3077 15.0005 12.6977L12.7575 14.9417C12.5515 15.1467 12.2765 15.2497 12.0005 15.2497Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Activation</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${
                    activationMenuOpen ? "show" : ""
                  }`}
                  id="sidebar-activation"
                  data-bs-parent="#sidebar"
                >
                  <li
                    className={`nav-item ${isActive(
                      "/activation/id-activation"
                    )}`}
                  >
                    <a className="nav-link" href="/activation/id-activation">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">Mining</span>
                    </a>
                  </li>

                  <li className={`nav-item ${isActive("/activation/staking")}`}>
                    <a className="nav-link" href="/activation/staking">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">Staking</span>
                    </a>
                  </li>
              
               
                
                </ul>
              </li>

              {/* Fund Manager */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#fund-manager"
                  role="button"
                  aria-expanded={isFundManagerOpen}
                  aria-controls="fund-manager"
                  onClick={() => setIsFundManagerOpen(!isFundManagerOpen)}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z"
                        fill="currentColor"
                      ></path>
                      <circle
                        cx="18"
                        cy="11.8999"
                        r="1"
                        fill="currentColor"
                      ></circle>
                    </svg>
                  </i>
                  <span className="item-name">Fund Manager</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${
                    isFundManagerOpen ? "show" : ""
                  }`}
                  id="fund-manager"
                  data-bs-parent="#sidebar"
                >
                  {/* <li
                    className={`nav-item ${isActive(
                      "/fund-manager/fund-request"
                    )}`}
                  >
                    <a className="nav-link" href="/fund-manager/fund-request">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> S </i>
                      <span className="item-name">Fund Request</span>
                    </a>
                  </li> */}
                  <li
                    className={`nav-item ${isActive(
                      "/fund-manager/fund-transfer"
                    )}`}
                  >
                    <a className="nav-link" href="/fund-manager/fund-transfer">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> F </i>
                      <span className="item-name">Fund Transfer</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/fund-manager/fund-received-history"
                    )}`}
                  >
                    <a
                      className="nav-link"
                      href="/fund-manager/fund-received-history"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> F </i>
                      <span className="item-name">Fund Receive History</span>
                    </a>
                  </li>
                </ul>
              </li>

              {/* Team & Member */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#team-member"
                  role="button"
                  aria-expanded={isTeamOpen}
                  aria-controls="team-member"
                  onClick={() => setIsTeamOpen(!isTeamOpen)}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.34933 14.8577C5.38553 14.8577 2 15.47 2 17.9173C2 20.3665 5.364 20.9999 9.34933 20.9999C13.3131 20.9999 16.6987 20.3876 16.6987 17.9403C16.6987 15.4911 13.3347 14.8577 9.34933 14.8577Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M16.1733 7.84873C16.1733 9.19505 15.7604 10.4513 15.0363 11.4948C14.961 11.6021 15.0275 11.7468 15.1586 11.7698C15.3406 11.7995 15.5275 11.8177 15.7183 11.8216C17.6165 11.8704 19.3201 10.6736 19.7907 8.87116C20.4884 6.19674 18.4414 3.79541 15.8338 3.79541C15.551 3.79541 15.2799 3.82416 15.0157 3.87686C14.9795 3.88453 14.9404 3.90177 14.9208 3.93244C14.8954 3.97172 14.914 4.02251 14.9394 4.05605C15.7232 5.13214 16.1733 6.44205 16.1733 7.84873Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M21.779 15.1693C21.4316 14.4439 20.593 13.9465 19.3171 13.7022C18.7153 13.5585 17.0852 13.3544 15.5695 13.3831C15.547 13.386 15.5343 13.4013 15.5324 13.4109C15.5294 13.4262 15.5363 13.4492 15.5656 13.4655C16.2662 13.8047 18.9737 15.2804 18.6332 18.3927C18.6185 18.5288 18.729 18.6438 18.867 18.6246C19.5333 18.5317 21.2476 18.1704 21.779 17.0474C22.0735 16.4533 22.0735 15.7634 21.779 15.1693Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Team & Member</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${isTeamOpen ? "show" : ""}`}
                  id="team-member"
                  data-bs-parent="#sidebar"
                >
                  <li
                    className={`nav-item ${isActive(
                      "/team-member/direct-referral"
                    )}`}
                  >
                    <a className="nav-link" href="/team-member/direct-referral">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> S </i>
                      <span className="item-name">Direct Referral</span>
                    </a>
                  </li>
                 
                  <li
                    className={`nav-item ${isActive(
                      "/team-member/level-team"
                    )}`}
                  >
                    <a className="nav-link" href="/team-member/level-team">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> F </i>
                      <span className="item-name">My Level Team</span>
                    </a>
                  </li>
                </ul>
              </li>

              {/* Coin & Manager */}
              <li className={`nav-item ${isCoinOpen ? "active" : ""}`}>
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#coin"
                  role="button"
                  aria-expanded={isCoinOpen}
                  aria-controls="coin"
                  onClick={() => setIsCoinOpen(!isCoinOpen)}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.18824 3.74722C9.18824 3.33438 8.84724 3 8.42724 3H8.42624L6.79724 3.00098C4.60624 3.00294 2.82324 4.75331 2.82324 6.90279V8.76201C2.82324 9.17386 3.16424 9.50923 3.58424 9.50923C4.00424 9.50923 4.34624 9.17386 4.34624 8.76201V6.90279C4.34624 5.57604 5.44624 4.4964 6.79824 4.49444L8.42724 4.49345C8.84824 4.49345 9.18824 4.15907 9.18824 3.74722ZM17.1931 3.00029H15.6001C15.1801 3.00029 14.8391 3.33468 14.8391 3.74751C14.8391 4.15936 15.1801 4.49277 15.6001 4.49277H17.1931C18.5501 4.49277 19.6541 5.57535 19.6541 6.90603V8.7623C19.6541 9.17415 19.9951 9.50952 20.4151 9.50952C20.8361 9.50952 21.1761 9.17415 21.1761 8.7623V6.90603C21.1761 4.75165 19.3901 3.00029 17.1931 3.00029ZM9.23804 6.74266H14.762C15.367 6.74266 15.948 6.98094 16.371 7.40554C16.797 7.83407 17.033 8.40968 17.032 9.00883V10.2542C17.027 10.4003 16.908 10.5189 16.759 10.5229H7.23904C7.09104 10.518 6.97204 10.3993 6.96904 10.2542V9.00883C6.95804 7.76837 7.97404 6.75541 9.23804 6.74266Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M22.239 12.0413H1.762C1.342 12.0413 1 12.3756 1 12.7885C1 13.2003 1.342 13.5337 1.762 13.5337H2.823V17.0972C2.823 19.2467 4.607 20.9971 6.798 20.999L8.427 21C8.848 21 9.188 20.6656 9.189 20.2528C9.189 19.841 8.848 19.5066 8.428 19.5066L6.8 19.5056C5.447 19.5036 4.346 18.424 4.346 17.0972V13.5337H6.969V14.5251C6.959 15.7656 7.974 16.7795 9.238 16.7913H14.762C16.027 16.7795 17.042 15.7656 17.032 14.5251V13.5337H19.655V17.0933C19.655 18.425 18.551 19.5066 17.194 19.5066H15.601C15.18 19.5066 14.839 19.841 14.839 20.2528C14.839 20.6656 15.18 21 15.601 21H17.194C19.39 21 21.177 19.2487 21.177 17.0933V13.5337H22.239C22.659 13.5337 23 13.2003 23 12.7885C23 12.3756 22.659 12.0413 22.239 12.0413Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Coin Manager</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${isCoinOpen ? "show" : ""}`}
                  id="coin"
                  data-bs-parent="#sidebar"
                >
                  <li className={`nav-item ${isActive("/coin/withdraw")}`}>
                    <a className="nav-link" href="/coin/withdraw">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> W </i>
                      <span className="item-name">Withdrawal</span>
                    </a>
                  </li>
                </ul>
              </li>

              {/* Financial Report */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  aria-expanded={isReportOpen}
                  aria-controls="report"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsReportOpen(!isReportOpen);
                  }}
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M7.36866 9.3689C6.91533 9.3689 6.54199 9.74223 6.54199 10.2045V17.0756C6.54199 17.5289 6.91533 17.9022 7.36866 17.9022C7.83088 17.9022 8.20421 17.5289 8.20421 17.0756V10.2045C8.20421 9.74223 7.83088 9.3689 7.36866 9.3689Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M12.0352 6.08887C11.5818 6.08887 11.2085 6.4622 11.2085 6.92442V17.0755C11.2085 17.5289 11.5818 17.9022 12.0352 17.9022C12.4974 17.9022 12.8707 17.5289 12.8707 17.0755V6.92442C12.8707 6.4622 12.4974 6.08887 12.0352 6.08887Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M16.6398 12.9956C16.1775 12.9956 15.8042 13.3689 15.8042 13.8312V17.0756C15.8042 17.5289 16.1775 17.9023 16.6309 17.9023C17.0931 17.9023 17.4664 17.5289 17.4664 17.0756V13.8312C17.4664 13.3689 17.0931 12.9956 16.6398 12.9956Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <span className="item-name">Financial Report</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${isReportOpen ? "show" : ""}`}
                  id="report"
                  data-bs-parent="#sidebar"
                >
                  <li
                    className={`nav-item ${isActive("/report/mining-income")}`}
                  >
                    <a className="nav-link" href="/report/mining-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> M </i>
                      <span className="item-name">Mining Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive("/report/level-income")}`}
                  >
                    <a className="nav-link" href="/report/level-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> L </i>
                      <span className="item-name">Level Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/report/overriding-income"
                    )}`}
                  >
                    <a className="nav-link" href="/report/overriding-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> O </i>
                      <span className="item-name">Overriding Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/report/fast-track-income"
                    )}`}
                  >
                    <a className="nav-link" href="/report/fast-track-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> F </i>
                      <span className="item-name">Fast Track Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive("/report/booster-income")}`}
                  >
                    <a className="nav-link" href="/report/booster-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> B </i>
                      <span className="item-name">Booster Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive("/report/reward-income")}`}
                  >
                    <a className="nav-link" href="/report/reward-income">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> R </i>
                      <span className="item-name">Reward Income</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/report/wallet-statement"
                    )}`}
                  >
                    <a className="nav-link" href="/report/wallet-statement">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> W </i>
                      <span className="item-name">Wallet Statement</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/report/wallet-outstanding"
                    )}`}
                  >
                    <a className="nav-link" href="/report/wallet-outstanding">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> O </i>
                      <span className="item-name">Wallet Outstanding</span>
                    </a>
                  </li>
                </ul>
              </li>

              {/* Wallet */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsWalletOpen(!isWalletOpen);
                  }}
                  role="button"
                  aria-expanded={isWalletOpen}
                  aria-controls="wallet"
                >
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z"
                        fill="currentColor"
                      ></path>
                      <circle
                        cx="18"
                        cy="11.8999"
                        r="1"
                        fill="currentColor"
                      ></circle>
                    </svg>
                  </i>
                  <span className="item-name">Wallet</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </a>
                <ul
                  className={`sub-nav collapse ${isWalletOpen ? "show" : ""}`}
                  id="wallet"
                >
                  <li
                    className={`nav-item ${isActive(
                      "/wallet/add-wallet-address"
                    )}`}
                  >
                    <a className="nav-link" href="/wallet/add-wallet-address">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">Add Wallet Address</span>
                    </a>
                  </li>
                  <li
                    className={`nav-item ${isActive(
                      "/activation/purchase-history"
                    )}`}
                  >
                    <a className="nav-link" href="/activation/purchase-history">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <span className="item-name">Purchase History</span>
                    </a>
                  </li>
                

                  <li className={`nav-item ${isActive("/wallet/withdraw")}`}>
                    <a className="nav-link" href="/wallet/withdraw">
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="1.5"
                          width="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="7.5"
                            stroke="currentColor"
                          ></circle>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> S </i>
                      <span className="item-name">Withdrawl</span>
                    </a>
                  </li>
                </ul>
              </li>

      
 {/* Calculator */}
 <li className={`nav-item ${isActive("/calculator")}`}>
                <a className="nav-link" href="/calculator">
                  <i className="icon">
                  <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 9H16V11H8V9ZM8 13H16V15H8V13Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8H20V18Z"
                        fill="currentColor"
                      />
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> D </i>
                  <span className="item-name">Calculator</span>
                </a>
              </li>






















              {/* support */}
              <li className={`nav-item ${isActive("/support")}`}>
                <a className="nav-link" href="/support">
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        opacity="0.4"
                        d="M14.4183 5.49C13.9422 5.40206 13.505 5.70586 13.4144 6.17054C13.3238 6.63522 13.6285 7.08891 14.0916 7.17984C15.4859 7.45166 16.5624 8.53092 16.8353 9.92995V9.93095C16.913 10.3337 17.2675 10.6265 17.6759 10.6265C17.7306 10.6265 17.7854 10.6215 17.8412 10.6115C18.3043 10.5186 18.609 10.0659 18.5184 9.60018C18.1111 7.51062 16.5027 5.89672 14.4183 5.49Z"
                        fill="currentColor"
                      ></path>{" "}
                      <path
                        opacity="0.4"
                        d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4078 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6698 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793Z"
                        fill="currentColor"
                      ></path>{" "}
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z"
                        fill="currentColor"
                      ></path>{" "}
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> D </i>
                  <span className="item-name">Support</span>
                </a>
              </li>
              {/* signout */}
              <li className="nav-item" onClick={handleLogout}>
                <a className="nav-link">
                  <i className="icon">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        opacity="0.4"
                        d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                        fill="currentColor"
                      ></path>{" "}
                      <path
                        d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                        fill="currentColor"
                      ></path>{" "}
                    </svg>{" "}
                  </i>
                  <i className="sidenav-mini-icon"> D </i>
                  <span className="item-name">Sign out</span>
                </a>
              </li>

              <li className="nav-item">
               
                  <i className="icon">
                  <button className="sidebar-toggle mobile" onClick={toggleSidebar}>
                  {isOpen ? "Close" : "Open"} 
              </button>
                  </i>
                
                
              
              </li>

            
            </ul>
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
      <main className="main-content">
        <div className="position-relative">
          <nav className="navbar navbar-expand-lg navbar-light iq-navbar border-bottom pb-lg-3 pt-lg-3">
            <div className="container-fluid navbar-inner">
              <h4 className="title"></h4>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <span className="navbar-toggler-bar bar1"></span>
                  <span className="navbar-toggler-bar bar2"></span>
                  <span className="navbar-toggler-bar bar3"></span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto navbar-list mb-2 mb-lg-0 align-items-center">
                  <li className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-link"
                      id="search-drop"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-label="Search dropdown"
                    >
                      <svg
                        width="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </a>
                    <ul
                      className="p-0 sub-drop dropdown-menu dropdown-menu-end"
                      aria-labelledby="search-drop"
                    >
                       <li>
      <div className="p-3 card-header justify-content-between border-bottom rounded-top">
        <div className="header-title">
          <button id="primary-btn" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </main>
    </>
  );
};

export default Sidebar;
