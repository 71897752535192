import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message } from 'antd'; // Import antd components

const ClaimReward = () => {
  const [data, setData] = useState({});
  const userToken = localStorage.getItem('userToken')

  useEffect(() => {
    fetchMiningData();
  }, []);

  const fetchMiningData = async () => {
    try {
      const response = await axios.get('https://api.vertexglobal.io/auth/v1/check-reward', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}` 
        }
      });

      setData(response.data);
      console.log("121212"+response.data);
    } catch (error) {
      console.error('Error fetching reward data:', error);
      message.error('Failed to load reward data');
    }
  };

  const handleClaim = async () => {
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.vertexglobal.io/auth/v1/claim-reward',
  headers: { 
    'Authorization': `Bearer ${userToken}` 
  },
  data : data
};

axios.request(config)
.then((response) => {
  message.success('Reward claimed successfully!');
})
.catch((error) => {
  message.error('Failed to claim reward');
});
  };

  return (
    <>
      {data.status && (
        <>
          <h5>{data.message}</h5>
          <Button 
            type="primary" 
            onClick={handleClaim} 
           id="primary-btn"
          >
         Claim Reward
          </Button>
        </>
      )}
    </>
  );
};

export default ClaimReward;
