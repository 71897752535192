import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from '../../../components/Table/Table';

const WalletStatement = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchMiningData();
  }, []);

  const fetchMiningData = async () => {
    try {
      const response = await axios.get('https://api.vertexglobal.io/auth/v1/fund/transactions', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}` 
        }
      });


      setData(response.data.transactions);
    } catch (error) {
      console.error('Error fetching mining data:', error);
    }
  };

  return (
    <>
      <DataTable heading="Wallet Statement" data={data} />
    </>
  );
};

export default WalletStatement;
