import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Table.css';

const DataTable = ({ heading, data, loading }) => {
  const [headers, setHeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingProgress, setLoadingProgress] = useState(0); 
  const rowsPerPage = 10;

  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedHeaders = Object.keys(data[0]);
      setHeaders(fetchedHeaders);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10; // Increment progress by 10 (for example)
        setLoadingProgress(progress);
        if (progress >= 100) clearInterval(interval);
      }, 200); // Adjust the interval to control the speed of the loading progress
    }
  }, [loading]);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="main-content">
      <div className="position-relative custom-page">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between pb-0 border-0">
              <div className="header-title">
                <h4 className="card-title">{heading}</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {loading ? (
                  <div className="loading-container">
                    {/* Display circular loader */}
                    <CircularProgressbar
                      value={loadingProgress}
                      text={`${loadingProgress}%`}
                      styles={buildStyles({
                        pathColor: `rgba(62, 152, 199, ${loadingProgress / 100})`,
                        textColor: '#fff',
                        trailColor: '#fff',
                        backgroundColor: '#3e98c7',
                        width:100,
                        height:100
                      })}
                    />
                    <p>Loading data, please wait...</p>
                  </div>
                ) : data.length === 0 ? (
                  <p>Data not found!</p>
                ) : (
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {headers.map((header) => (
                            <th key={header}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {headers.map((header) => (
                              <td key={header}>{row[header]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination>
                      {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
