import React, { useEffect, useState } from 'react';
import { Collapse, message } from 'antd';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import './Level.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const { Panel } = Collapse;

const LevelTeam = () => {
  const [levelTeamData, setLevelTeamData] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    if (loading) {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10; // Increment progress by 10 (for example)
        setLoadingProgress(progress);
        if (progress >= 100) clearInterval(interval);
      }, 200); // Adjust the interval to control the speed of the loading progress

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [loading]);

  useEffect(() => {
    const fetchLevelTeam = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.vertexglobal.io/auth/v1/level-team',
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        };

        const response = await axios.request(config);
        const { data } = response.data;

        // Set the data to state and stop loading
        setLevelTeamData(data);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        message.error('Failed to fetch level team data.');
        console.error(error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchLevelTeam();
  }, []);

  return (
    <div className="main-content custom-page">
      <h4>Level Team Structure</h4>
      {loading ? ( // Show loader while loading
        <CircularProgressbar
          value={loadingProgress}
          text={`${loadingProgress}%`}
          styles={buildStyles({
            pathColor: `rgba(62, 152, 199, ${loadingProgress / 100})`,
            textColor: '#fff',
            trailColor: '#fff',
            backgroundColor: '#3e98c7',
            width: 100,
            height: 100
          })}
        />
      ) : levelTeamData.length > 0 ? (
        <Collapse accordion>
          {levelTeamData.map((level) => (
            <Panel header={`Level ${level.level} (Total Members: ${level.users.length})`} key={`level-${level.level}`}>
              {level.users.map((user, index) => (
                <Collapse key={user.userName} accordion>
                  <Panel header={`${user.fullName} (${user.userName})`} key={`${user.userName}-${index}`}>
                    <div className="table-responsive"> 
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Username</th>
                            <th>Email Address</th>
                            <th>Total Investment</th>
                            <th>Status</th>
                            <th>Activation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{user.fullName}</td>
                            <td>{user.userName}</td>
                            <td>{user.email}</td>
                            <td>{user.totalInvestment}</td>
                            <td>{user.totalInvestment > 0 ? <>Active</> : <>Inactive</>}</td>
                            <td>{moment(user.firstCreditDate).format('DD-MM-YYYY | HH:mm')}</td>    
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <p>No data found!</p> // Handle case when no data is returned
      )}
    </div>
  );
};

export default LevelTeam;
