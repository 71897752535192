import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, message } from 'antd';
import DataTable from '../../../components/Table/Table';

const { Option } = Select;

const CoinStaking = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [stakingPackages, setStakingPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [stakeAmount, setStaking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // To track button state
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get('https://api.vertexglobal.io/auth/v1/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserDetails(userResponse.data.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const fetchStakingPackages = async () => {
      try {
        const packagesResponse = await axios.get('https://api.vertexglobal.io/auth/v1/staking/all', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setStakingPackages(packagesResponse.data);
      } catch (error) {
        console.error('Error fetching staking packages:', error);
      }
    };

    const fetchStake = async () => {
      try {
        const packagesResponse = await axios.get('https://api.vertexglobal.io/auth/v1/mystake', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setStaking(packagesResponse.data.stakings);
      } catch (error) {
        console.error('Error fetching staking data:', error);
      }
    };

    fetchStake();
    fetchUserData();
    fetchStakingPackages();
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get('https://api.vertexglobal.io/auth/v1/mystake', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setTeamData(response.data.stakings);
      } catch (error) {
        console.error('Error fetching team data:', error);
        message.error('Error fetching team data');
      }
    };

    fetchTeamData();
  }, [token]);

  const handleUpdateClick = async () => {
    if (!selectedPackage || !investmentAmount) {
      message.error('Please select a package and enter an amount.');
      return;
    }

    // Disable button immediately after clicking
    setIsButtonDisabled(true);

    try {
      const data = JSON.stringify({
        packageId: selectedPackage,
        amount: investmentAmount,
      });

      await axios.post('https://api.vertexglobal.io/auth/v1/addstaking', data, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      message.success('Staking request submitted successfully!');
      
      // Refresh data or call the necessary APIs again
      const updatedStakeData = await axios.get('https://api.vertexglobal.io/auth/v1/mystake', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setStaking(updatedStakeData.data.stakings);

      // Optionally refresh page
      // window.location.reload();
    } catch (error) {
      console.error('Error submitting staking request:', error);
      message.error('Failed to submit staking request.');
    } finally {
      // Re-enable the button after request is done
      setIsButtonDisabled(false);
    }
  };

  return (
    <main className="main-content">
      <div className="position-relative custom-page">
        <h1 className="title">Staking</h1>
        <div className="form-comp">
          {/* User Details Fields */}
          <div className="form-floating mb-3">
            <input type="text" className="form-control" value={userDetails ? userDetails.userName : ''} readOnly />
            <label>User Id *</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" value={userDetails ? userDetails.fullName : ''} readOnly />
            <label>Name *</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" value={userDetails ? userDetails.walletAmount : ''} readOnly />
            <label>Purchase Wallet *</label>
          </div>

          <div className="form-floating mb-3">
            <input type="number" className="form-control" value={userDetails ? userDetails.tokenRate : ''} readOnly />
            <label>Token Rate *</label>
          </div>

          {/* Staking Selection Fields */}
          <div className="form-floating mb-3">
            <Select
              placeholder="Select Currency"
              className="form-control"
              onChange={value => setSelectedPackage(value)}
              style={{ width: '100%' }}
            >
              {stakingPackages.map(pkg => (
                <Option key={pkg._id} value={pkg._id}>
                  {pkg.name} - {pkg.returnPercentage}% Return
                </Option>
              ))}
            </Select>
            <label>Select Staking Months</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Investment Amount"
              value={investmentAmount}
              onChange={e => setInvestmentAmount(e.target.value)}
            />
            <label>Investment Amount *</label>
          </div>

          {/* Stake Now Button */}
          <button
            id="primary-btn"
            onClick={handleUpdateClick}
            disabled={isButtonDisabled} // Disable button if state is true
          >
            {isButtonDisabled ? 'Processing...' : 'Stake Now'}
          </button>
        </div>

       
       
      </div>
      <DataTable heading="Coin Staking History" data={teamData || []} />
    </main>
  );
};

export default CoinStaking;
